import { PlaidLinkError } from 'react-plaid-link';

export interface LinkTokenState {
  byCustomer: { [key: string]: string | null };
  byInstitution: { [key: string]: string | null };
  error: PlaidLinkError | null;
}

interface ActionPayload {
  id: string;
  token: string | null;
}

interface CreateToken extends ActionPayload {
  type: 'LINK_TOKEN_CREATED';
}

interface UpdateToken extends ActionPayload {
  type: 'LINK_TOKEN_UPDATE_MODE_CREATED';
}

interface DeleteToken extends ActionPayload {
  type: 'DELETE_CUSTOMER_LINK_TOKEN';
}

interface DeleteInstitutionToken extends ActionPayload {
  type: 'DELETE_INSTITUTION_LINK_TOKEN';
}

interface SetError {
  type: 'SET_ERROR';
  error: PlaidLinkError | null;
}

interface ResetError {
  type: 'RESET_ERROR';
}

interface ClearTokens {
  type: 'CLEAR_TOKENS';
}

type Action =
  | CreateToken
  | UpdateToken
  | DeleteToken
  | DeleteInstitutionToken
  | SetError
  | ResetError
  | ClearTokens;

export const linkReducer: React.Reducer<LinkTokenState, Action> = (
  state: LinkTokenState,
  action: Action
): LinkTokenState => {
  switch (action.type) {
    case 'LINK_TOKEN_CREATED':
      return {
        ...state,
        byCustomer: {
          [action.id]: action.token,
        },
        error: null,
      };

    case 'LINK_TOKEN_UPDATE_MODE_CREATED':
      return {
        ...state,
        error: null,
        byInstitution: {
          ...state.byInstitution,
          [action.id]: action.token,
        },
      };
    case 'DELETE_CUSTOMER_LINK_TOKEN':
      return {
        ...state,
        byCustomer: {
          [action.id]: '',
        },
      };
    case 'DELETE_INSTITUTION_LINK_TOKEN':
      return {
        ...state,
        byInstitution: {
          ...state.byInstitution,
          [action.id]: '',
        },
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.error,
      };
    case 'RESET_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'CLEAR_TOKENS':
      return {
        ...state,
        byCustomer: {},
        byInstitution: {},
      };
    default:
      return state;
  }
};
