import { useCallback, useState } from 'react';

import { MobileAllocationTableFilter } from '../types/allocationTable';

export const useTimeOrPercentageFilter = () => {
  const [filter, setFilter] = useState<MobileAllocationTableFilter>(
    MobileAllocationTableFilter.Value
  );

  const toggleFilter = useCallback(() => {
    setFilter(
      filter === MobileAllocationTableFilter.Value
        ? MobileAllocationTableFilter.Percentage
        : MobileAllocationTableFilter.Value
    );
  }, []);

  return { filter, toggleFilter, setFilter };
};
