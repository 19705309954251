import { PortfolioManagedBySelector } from '@generated';
import { TimeFilterDuration } from 'src/features/graphs/types/timeFilters';

// set to `undefined` as default, because `null` is used to indicate that
// the user has explicitly selected "All time"
export const timeFiltersInitialValues: Record<
  PortfolioManagedBySelector,
  TimeFilterDuration | undefined
> = {
  [PortfolioManagedBySelector.All]: undefined,
  [PortfolioManagedBySelector.Secfi]: undefined,
  [PortfolioManagedBySelector.Self]: undefined,
};
