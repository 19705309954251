import { FetchResult } from '@apollo/client';
import {
  ExchangePublicTokenMutation,
  useExchangePublicTokenMutation,
  useGetLinkTokenForUpdateMutation,
  useGetLinkTokenMutation,
} from '@generated';
import React, { useCallback, useMemo, useReducer } from 'react';
import { PlaidLinkError } from 'react-plaid-link';
import { LaunchPlaidLink } from 'src/components/LaunchPlaidLink/LaunchPlaidLink';
import { secfiStorage } from 'src/services/secfiStorage';

import { LinkTokenState, linkReducer } from './linkReducer';

interface LinkContextType {
  generateLinkToken: (customer: string, institution?: string) => Promise<void>;
  deleteLinkToken: (customer: string | undefined, institution?: string) => void;
  launchPlaidLink: (args: React.ComponentProps<typeof LaunchPlaidLink>) => void;
  setError: (error: PlaidLinkError | null) => void;
  resetError: () => void;
  linkTokens: LinkTokenState;
  clearTokens: () => void;
  exchangePublicTokenMutation: ReturnType<
    typeof useExchangePublicTokenMutation
  >[0];
  isExchangingPublicTokens: boolean;
}

const LinkContext = React.createContext<LinkContextType>({
  generateLinkToken: () => Promise.resolve(),
  launchPlaidLink: () => null,
  deleteLinkToken: () => null,
  setError: () => null,
  resetError: () => null,
  clearTokens: () => null,
  linkTokens: {
    byCustomer: {},
    byInstitution: {},
    error: null,
  },
  exchangePublicTokenMutation: () =>
    Promise.resolve({} as FetchResult<ExchangePublicTokenMutation>),
  isExchangingPublicTokens: false,
});

const plaidLinkTokenStorageKey = 'plaid_link_token';

const initialState = {
  byCustomer: {},
  byInstitution: {},
  error: null,
};

export const useLink = () => {
  const context = React.useContext(LinkContext);
  if (context === undefined) {
    throw new Error('useLink must be used within a LinkProvider');
  }
  return context;
};

export const LinkProvider = ({ children }: { children: React.ReactNode }) => {
  const [getLinkToken] = useGetLinkTokenMutation();
  const [getLinkTokenForUpdate] = useGetLinkTokenForUpdateMutation();
  const [linkTokens, dispatch] = useReducer(linkReducer, initialState);
  const [linkProps, setLinkProps] = React.useState<React.ComponentProps<
    typeof LaunchPlaidLink
  > | null>(null);

  const [
    exchangePublicTokenMutation,
    { loading: isExchangingPublicTokens },
  ] = useExchangePublicTokenMutation();

  const setError = useCallback(
    (error: PlaidLinkError | null) => {
      dispatch({
        type: 'SET_ERROR',
        error,
      });
    },
    [dispatch]
  );

  const resetError = useCallback(() => {
    dispatch({
      type: 'RESET_ERROR',
    });
  }, [dispatch]);

  /**
   * Generate a link token for a customer or institution.
   * If an institution is provided, the token will be generated in update mode.
   * @param customer The customer ID
   * @param institution The institution ID
   * @throws Error if the token could not be generated
   *
   * We only want to initialize the link token once per customer or institution, and only
   * when the user actually clicks the button. This is because the link token is only
   * valid for 30 minutes, and we don't want to generate a new one every time the portfolio
   * route is loaded.
   */
  const generateLinkToken = useCallback(
    async (customer: string, institution?: string) => {
      const promise =
        typeof institution === 'string'
          ? getLinkTokenForUpdate({
              variables: {
                customer,
                institution,
              },
            })
          : getLinkToken({ variables: { customer } });

      try {
        const result = await promise;

        const linkToken = result?.data?.token;

        if (linkToken) {
          secfiStorage.setItem(plaidLinkTokenStorageKey, linkToken);
          if (institution) {
            dispatch({
              type: 'LINK_TOKEN_UPDATE_MODE_CREATED',
              id: institution,
              token: linkToken,
            });
          } else {
            dispatch({
              type: 'LINK_TOKEN_CREATED',
              id: customer,
              token: linkToken,
            });
          }
        } else {
          throw new Error('Failed to obtain link token');
        }
      } catch (error) {
        if (error instanceof Error) {
          throw error.message;
        }
      }
    },
    []
  );

  const deleteLinkToken = useCallback(
    (customer: string | undefined, institution?: string) => {
      if (institution) {
        dispatch({
          type: 'DELETE_INSTITUTION_LINK_TOKEN',
          id: institution,
          token: null,
        });
      } else if (customer) {
        dispatch({
          type: 'DELETE_CUSTOMER_LINK_TOKEN',
          id: customer,
          token: null,
        });
      }
    },
    []
  );

  const launchPlaidLink = useCallback(
    (args: React.ComponentProps<typeof LaunchPlaidLink>) => {
      setLinkProps(args);
    },
    []
  );

  const renderPlaidLink = useMemo(() => {
    if (!linkProps) {
      return null;
    }

    return <LaunchPlaidLink {...linkProps} />;
  }, [linkProps]);

  const clearTokens = useCallback(() => {
    dispatch({
      type: 'CLEAR_TOKENS',
    });
  }, []);

  const value = useMemo(
    () => ({
      generateLinkToken,
      deleteLinkToken,
      linkTokens,
      setError,
      resetError,
      clearTokens,
      exchangePublicTokenMutation,
      isExchangingPublicTokens,
      launchPlaidLink,
    }),
    [linkTokens, generateLinkToken, deleteLinkToken, isExchangingPublicTokens]
  );

  return (
    <LinkContext.Provider value={value}>
      {children}
      {renderPlaidLink}
    </LinkContext.Provider>
  );
};
