import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import { analytics } from 'src/services/analytics/analyticsService';

import {
  PortfolioTrackingEvents,
  portfolioProductName,
} from '../types/analytics';

const trackPortfolioEvent = (
  eventName: PortfolioTrackingEvents,
  data?: Record<string, unknown>
) => {
  analytics.track(eventName, { ...data, productName: portfolioProductName });
};

interface TrackGraphViewedData {
  page: string;
  dataPointsCount: number;

  /** Only set when on overview or PE page. */
  stockOptionsVested?: boolean;
  /** Only set when on overview. */
  assets?: string[];
  /** Only set when on PE page. */
  hasDynamicData?: boolean;
}

export const trackGraphViewed = (data: TrackGraphViewedData) => {
  trackPortfolioEvent(PortfolioTrackingEvents.PortfolioGraphViewed, {
    ...data,
  });
};

export const trackUserSelectedTimeFilter = (
  page: string,
  timeFilter: string
) => {
  trackPortfolioEvent(PortfolioTrackingEvents.PortfolioUserSelectedTimeFilter, {
    page,
    timeFilter,
  });
};

export const trackAddAssetButtonClicked = (page: string) => {
  trackPortfolioEvent(PortfolioTrackingEvents.PortfolioAddAssetButtonClicked, {
    page,
  });
};

export const trackAddAnotherAssetClicked = (page: string) => {
  trackPortfolioEvent(PortfolioTrackingEvents.PortfolioAddAnotherAssetClicked, {
    page,
  });
};

export const trackManageOrAddGrantsClicked = (
  page: string,
  companyName: string
) => {
  trackPortfolioEvent(
    PortfolioTrackingEvents.PortfolioManageOrAddGrantsClicked,
    { companyName, page }
  );
};

export const trackFeedbackButtonClicked = (page: string) => {
  trackPortfolioEvent(PortfolioTrackingEvents.PortfolioFeedbackButtonClicked, {
    page,
  });
};

export const trackCompanySettingsButtonClicked = (
  page: string,
  companyName: string
) => {
  trackPortfolioEvent(
    PortfolioTrackingEvents.PortfolioCompanySettingsButtonClicked,
    { companyName, page }
  );
};

export const trackPlaidIntegrationOnExit = (
  page: string,
  error: null | PlaidLinkError,
  metadata: PlaidLinkOnExitMetadata
) => {
  trackPortfolioEvent(PortfolioTrackingEvents.PortfolioPlaidOnExit, {
    error,
    metadata,
    page,
  });
};

export const trackPlaidIntegrationOnSuccess = (
  page: string,
  metadata: PlaidLinkOnSuccessMetadata
) => {
  trackPortfolioEvent(PortfolioTrackingEvents.PortfolioPlaidOnSuccess, {
    metadata,
    page,
  });
};
