import { Company } from '@generated';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const EST_TIME_ZONE = 'America/New_York';
export const isPublicCompanyFragment = (
  company?: Pick<Company, 'ipo_date'> | null
): boolean => {
  if (!company?.ipo_date) return false;

  const ipoDate = dayjs(company.ipo_date).tz(EST_TIME_ZONE);
  const now = dayjs().tz(EST_TIME_ZONE);

  // if today's date is before the IPO date return false
  if (now.isBefore(ipoDate, 'day')) return false;

  // if today's date equals the IPO date and the time is
  // after 12 pm EST, return true; otherwise, return false
  if (now.isSame(ipoDate, 'day')) {
    return ipoDate.hour() >= now.hour();
  }

  return true;
};
