import { useApolloClient } from '@apollo/client';
import {
  CartaConnectionStatus,
  GetCartaConnectionStatusDocument,
  useGetCartaConnectionStatusQuery,
  useImportCartaDataMutation,
} from '@generated';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PORTFOLIO_REFETCH_QUERIES } from 'src/+portfolio/constants/refetch';
import { cartaAccessDeniedErrorCode } from 'src/constants/carta';
import { manageCartaConnectionWording } from 'src/constants/wording';
import { useGrants } from 'src/hooks/queries/useGrants';
import { useNotificationContext } from 'src/providers/NotificationProvider/useNotificationContext';
import { useAuthenticatedUser } from 'src/services/authentication/hooks';
import { NotificationType } from 'src/types/notification';

import {
  ConnectionStatusCallbacks,
  useHandleCartaConnectionStatus,
} from './useHandleCartaConnectionStatus';
import { useQueryParams } from './useQueryParams/useQueryParams';

export const useManageCartaConnection = () => {
  const ref = useRef<CartaConnectionStatus | undefined>(undefined);
  const { user } = useAuthenticatedUser();

  const [isSyncing, setIsSyncing] = useState(false);

  const {
    data,
    refetch,
    startPolling,
    stopPolling,
  } = useGetCartaConnectionStatusQuery({
    notifyOnNetworkStatusChange: true,
    skip: !user?.uuid,
  });

  const { user: currentUser } = useAuthenticatedUser();
  const { showNotification } = useNotificationContext();

  const date = data?.getCartaConnectionStatus?.lastUpdatedAt;
  const status = data?.getCartaConnectionStatus?.status;

  useEffect(() => {
    if (status) {
      ref.current = status;
    }
  }, [status]);

  const [
    scheduleCartaRefresh,
    { loading: isScheduling },
  ] = useImportCartaDataMutation({
    onCompleted: () => {
      setIsSyncing(true);
      startPolling(1500);
    },
    refetchQueries: [
      {
        query: GetCartaConnectionStatusDocument,
      },
    ],
    awaitRefetchQueries: true,
    onError: () => {
      showNotification(
        NotificationType.Error,
        manageCartaConnectionWording.somethingWentWrong
      );
    },
  });

  const client = useApolloClient();

  const [hasInitiatedConnection, setHasInitiatedConnection] = useState(false);

  const customerUuid = currentUser?.customer?.uuid;

  const { refetch: refetchGrants } = useGrants({
    customerUuid,
  });

  const [cartaCallbackError] = useQueryParams('error');

  const callbacks: ConnectionStatusCallbacks = useMemo(
    () => ({
      onNotStarted: () => {
        if (cartaCallbackError === cartaAccessDeniedErrorCode) {
          stopPolling();
          setIsSyncing(false);
          showNotification(
            NotificationType.Warn,
            manageCartaConnectionWording.youDidntSelectPortfolios
          );
        } else {
          setTimeout(refetch, 2000);
        }
      },
      onInProgress: noop,
      onCompleted: async () => {
        stopPolling();
        if (hasInitiatedConnection) {
          await Promise.all([
            refetchGrants({ customer: customerUuid }),
            client.refetchQueries({
              include: [
                'GetAllAffiliationOverview',
                ...PORTFOLIO_REFETCH_QUERIES,
              ],
            }),
          ]);
          setIsSyncing(false);
          showNotification(
            NotificationType.Success,
            manageCartaConnectionWording.success
          );
        }
      },
      onError: () => {
        stopPolling();
        if (hasInitiatedConnection) {
          setIsSyncing(false);
          showNotification(
            NotificationType.Error,
            manageCartaConnectionWording.somethingWentWrong
          );
        }
      },
      onDisconnected: async () => {
        if (!isNil(ref) && !isNil(status) && ref?.current !== status) {
          await Promise.all([
            refetchGrants({ customer: customerUuid }),
            client.refetchQueries({
              include: [
                'GetAllAffiliationOverview',
                ...PORTFOLIO_REFETCH_QUERIES,
              ],
            }),
          ]);
        }
        stopPolling();
        setIsSyncing(false);
      },
    }),
    [
      cartaCallbackError,
      client,
      customerUuid,
      hasInitiatedConnection,
      ref,
      refetch,
      refetchGrants,
      showNotification,
      startPolling,
      status,
      stopPolling,
    ]
  );

  useHandleCartaConnectionStatus(status, callbacks);

  const onRefreshClick = async () => {
    if (isSyncing) {
      return showNotification(
        NotificationType.Error,
        manageCartaConnectionWording.pleaseWaitForCurrentSyncToFinish
      );
    }
    setHasInitiatedConnection(true);
    await scheduleCartaRefresh();
  };

  return {
    status,
    onRefreshClick,
    isSyncing,
    isScheduling,
    lastUpdated: date,
  };
};
