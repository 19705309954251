import { CartaConnectionStatus } from '@generated';
import { useCallback, useEffect } from 'react';

export interface ConnectionStatusCallbacks {
  onNotStarted: () => void | Promise<void>;
  onInProgress: () => void | Promise<void>;
  onCompleted: () => void | Promise<void>;
  onError: (status: CartaConnectionStatus) => void | Promise<void>;
  onDisconnected: () => void | Promise<void>;
}

const {
  NotStarted,
  InProgress,
  Completed,
  CompletedWithErrors,
  Error,
  PermissionError,
  ConnectionError,
  Disconnected,
} = CartaConnectionStatus;

export const useHandleCartaConnectionStatus = (
  status: CartaConnectionStatus | null | undefined,
  callbacks: ConnectionStatusCallbacks
) => {
  const handleConnectionStatus = useCallback(async () => {
    if (!status) return;

    switch (status) {
      case NotStarted:
        await callbacks.onNotStarted();
        break;
      case InProgress:
        await callbacks.onInProgress();
        break;
      case Completed:
      case CompletedWithErrors:
        await callbacks.onCompleted();
        break;
      case Error:
      case PermissionError:
      case ConnectionError:
        await callbacks.onError(status);
        break;
      case Disconnected:
        await callbacks.onDisconnected();
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    handleConnectionStatus().catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error handling connection status: ', error);
    });
  }, [status]);
};
