import { GetPortfolioOverviewSyncStatusDocument } from '@generated';
import noop from 'lodash/noop';
import { memo, useCallback, useEffect, useMemo } from 'react';
import {
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import { usePortfolioContext } from 'src/+portfolio/providers/PortfolioContext';
import { useNetWorthContext } from 'src/+portfolio/providers/useNetWorthContext';
import {
  trackPlaidIntegrationOnExit,
  trackPlaidIntegrationOnSuccess,
} from 'src/+portfolio/services/analyticsActions';
import { plaidIntegrationWording } from 'src/constants/wording';
import { useLink } from 'src/providers/LinkProvider/LinkProvider';
import { useNotificationContext } from 'src/providers/NotificationProvider/useNotificationContext';
import { secfiStorage } from 'src/services/secfiStorage';
import { NotificationType } from 'src/types/notification';

interface LaunchLinkProps {
  isOauth?: boolean;
  token: string;
  customerUuid: string;
  institutionUuid?: string;
  source: string;
  onLoad?: () => void;
  onExit?: (metadata?: PlaidLinkOnExitMetadata) => void;
  customSuccessHandler?: (
    token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => void;
}

const plaidOauthRedirectParameterName = 'oauth_state_id';
const plaidLinkTokenStorageKey = 'plaid_link_token';

/**
 * Helper component to trigger the plaid link flow.
 * This allows us to only load plaid once all the requirements are met.
 * The linkToken is generated in the LinkProvider, and passed down to this component.
 */
export const LaunchPlaidLink = memo(
  ({
    isOauth,
    token,
    customerUuid,
    institutionUuid,
    source,
    customSuccessHandler,
    onLoad,
    onExit: onExitProp,
  }: LaunchLinkProps) => {
    const { managedBy } = usePortfolioContext();

    const isOauthRedirect =
      isOauth ??
      window.location.href.includes(plaidOauthRedirectParameterName + '=');

    const {
      generateLinkToken,
      setError,
      resetError,
      clearTokens,
      exchangePublicTokenMutation,
    } = useLink();
    const {
      startConnectingInstitution,
      updateConnectedInstitutions,
    } = useNetWorthContext();
    const { showNotification } = useNotificationContext();

    const onSuccess = useCallback<PlaidLinkOnSuccess>(
      async (publicToken, metadata) => {
        trackPlaidIntegrationOnSuccess(source, metadata);

        if (typeof customSuccessHandler === 'function') {
          customSuccessHandler(token, metadata);
          resetError();
          clearTokens();
          return;
        }

        try {
          if (institutionUuid) {
            // update mode, no need to generate a new link token
            resetError();
            clearTokens();
          } else {
            // default link mode, send public_token to your server
            // https://plaid.com/docs/api/tokens/#token-exchange-flow

            startConnectingInstitution();
            await exchangePublicTokenMutation({
              variables: {
                customerUuid,
                publicToken,
              },
              refetchQueries: [
                {
                  query: GetPortfolioOverviewSyncStatusDocument,
                  variables: {
                    customer: customerUuid,
                    managedBy,
                  },
                },
                'GetSyncStatusForAssetType',
              ],
              awaitRefetchQueries: true,
              onCompleted: (data) => {
                if (data?.exchangePublicToken) {
                  showNotification(
                    NotificationType.Success,
                    plaidIntegrationWording.successMessage
                  );
                }
              },
              onError: () => {
                showNotification(
                  NotificationType.Error,
                  plaidIntegrationWording.errorMessage
                );
              },
            });

            resetError();
            clearTokens();
          }
          updateConnectedInstitutions();
        } catch (error) {
          noop();
        }
      },
      [
        startConnectingInstitution,
        updateConnectedInstitutions,
        exchangePublicTokenMutation,
        customerUuid,
        showNotification,
        customSuccessHandler,
      ]
    );

    const onExit = useCallback<PlaidLinkOnExit>(
      async (error, metadata) => {
        // TODO: handle errors in UI
        // https://plaid.com/docs/link/web/#onexit
        trackPlaidIntegrationOnExit(source, error, metadata);
        if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
          // If the link token is invalid, we need to generate a new one.
          await generateLinkToken(customerUuid, institutionUuid);
        }
        if (typeof onExitProp === 'function') {
          onExitProp(metadata);
        }
        clearTokens();
      },
      [source]
    );

    const onEvent = (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata
    ) => {
      if (eventName === 'EXIT') {
        resetError();
        clearTokens();
      }
      // handle errors in the event end-user does not exit with onExit function error enabled.
      if (eventName === 'ERROR' && metadata.error_code != null) {
        showNotification(
          NotificationType.Error,
          plaidIntegrationWording.genericError
        );
        // TODO: handle errors in UI
        setError({
          error_code: metadata.error_code,
          error_message: metadata.error_message ?? '',
          error_type: metadata.error_type ?? '',
          display_message: metadata.error_message ?? '',
        });
      }
    };

    const config: PlaidLinkOptions = useMemo(() => {
      const config: PlaidLinkOptions = {
        // Token must be the same token used for the first initialization of Link
        token,
        onSuccess,
        onExit,
        onEvent,
        onLoad,
      };

      if (isOauthRedirect) {
        // receivedRedirectUri must include the query params
        config.receivedRedirectUri = window.location.href;
      }

      return config;
    }, [token, onSuccess, onExit, isOauthRedirect]);

    const { open, ready: isReady } = usePlaidLink(config);

    useEffect(() => {
      // initiallizes Link automatically
      if (isOauthRedirect && isReady) {
        open();
      } else if (isReady) {
        // regular, non-OAuth case:
        // set link token to local storage for use if needed later by OAuth
        secfiStorage.setItem(plaidLinkTokenStorageKey, token);
        open();
      }
    }, [isReady, open, isOauthRedirect, customerUuid, institutionUuid, token]);

    return null;
  }
);

LaunchPlaidLink.displayName = 'LaunchPlaidLink';
