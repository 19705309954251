import { PortfolioManagedBySelector } from '@generated';
import React from 'react';
import { timeFiltersInitialValues } from 'src/+portfolio/constants/timeFilters';
import { TimeFilterDuration } from 'src/features/graphs/types/timeFilters';
import { noop } from 'src/types/basic';

import { useTimeOrPercentageFilter } from '../hooks/useTimeOrPercentageFilter';
import { MobileAllocationTableFilter } from '../types/allocationTable';

interface PortfolioContextValue {
  availableTimeFilters: Record<
    PortfolioManagedBySelector,
    TimeFilterDuration | undefined
  >;
  setAvailableTimeFilters: (
    value: Record<PortfolioManagedBySelector, TimeFilterDuration | undefined>
  ) => void;
  managedBy: PortfolioManagedBySelector;
  setManagedBy: React.Dispatch<
    React.SetStateAction<PortfolioManagedBySelector>
  >;
  filter: MobileAllocationTableFilter;
  toggleFilter: (filter: string) => void;
  setFilter: React.Dispatch<React.SetStateAction<MobileAllocationTableFilter>>;
}

const defaultContextValue: PortfolioContextValue = Object.freeze({
  managedBy: PortfolioManagedBySelector.All,
  setManagedBy: noop,
  filter: MobileAllocationTableFilter.Value,
  toggleFilter: noop,
  setFilter: noop,
  availableTimeFilters: timeFiltersInitialValues,
  setAvailableTimeFilters: noop,
});

const PortfolioContext = React.createContext<PortfolioContextValue>(
  defaultContextValue
);

export const usePortfolioContext = () => {
  const context = React.useContext(PortfolioContext);
  if (context === undefined) {
    throw new Error(
      'usePortfolioContext must be used within a PortfolioProvider'
    );
  }
  return context;
};

export const PortfolioProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [managedBy, setManagedBy] = React.useState<PortfolioManagedBySelector>(
    PortfolioManagedBySelector.All
  );
  const [availableTimeFilters, setAvailableTimeFilters] = React.useState<
    Record<PortfolioManagedBySelector, TimeFilterDuration | undefined>
  >(timeFiltersInitialValues);
  const { filter, toggleFilter, setFilter } = useTimeOrPercentageFilter();

  return (
    <PortfolioContext.Provider
      value={{
        availableTimeFilters,
        setAvailableTimeFilters,
        managedBy,
        setManagedBy,
        filter,
        toggleFilter,
        setFilter,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};
