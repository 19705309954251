import noop from 'lodash/noop';
import React from 'react';

export interface NetWorthContextValue {
  isConnectingInstitution: boolean;
  isMissingConnectedInstitution: boolean;

  startConnectingInstitution: () => void;
  updateConnectedInstitutions: () => void;
}

const defaultContextValue: NetWorthContextValue = Object.freeze({
  isConnectingInstitution: false,
  isMissingConnectedInstitution: true,

  startConnectingInstitution: noop,
  updateConnectedInstitutions: noop,
});

export const NetWorthContext = React.createContext<NetWorthContextValue>(
  defaultContextValue
);
