import { AllocationTableEntryFragment, PortfolioErrorCode } from '@generated';
import { ReactNode } from 'react';
import { AllocationChartItem } from 'src/types/allocation';

export enum MobileAllocationTableFilter {
  Value = 'value',
  Percentage = 'percentage',
}

export type AllocationTableEntryForOverlay = Pick<
  AllocationTableEntryFragment,
  'name' | 'errors'
>;

export interface AllocationRow {
  cells: ReactNode[];
  handleClick?: (rowIndex: number) => void;
}

export interface MappedTableData {
  chartLines: AllocationChartItem[];
  rows: AllocationRow[];
  hasPrivateEquity: boolean;
}

export interface AllocationColumn {
  name: string;
  tooltip?: string;
  align: 'left' | 'right';
  weight?: number;
}

export type HoverableTableErrorIcon = 'warning' | 'add';

export interface AllocationRowError {
  code: PortfolioErrorCode;
  isBlocking: boolean;
  message: string;
  icon?: HoverableTableErrorIcon;
}

export type AllocationTableVariant = 'default' | 'private-equity';
