import { CartaConnectionStatus } from '@generated';
import React, { createContext, useContext } from 'react';
import { useManageCartaConnection } from 'src/hooks/useManageCartaConnection';

const initialState = {
  status: undefined,
  onRefreshClick: () => null,
  isSyncing: false,
  isScheduling: false,
  lastUpdated: undefined,
};

interface CartaConnectionState {
  status: CartaConnectionStatus | undefined;
  onRefreshClick: () => void;
  isSyncing: boolean;
  isScheduling: boolean;
  lastUpdated: string | undefined;
}

const CartaConnectionContext = createContext<CartaConnectionState>(
  initialState
);

export const CartaConnectionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    status,
    onRefreshClick,
    isSyncing,
    isScheduling,
    lastUpdated,
  } = useManageCartaConnection();

  return (
    <CartaConnectionContext.Provider
      value={{ status, onRefreshClick, isSyncing, isScheduling, lastUpdated }}
    >
      {children}
    </CartaConnectionContext.Provider>
  );
};

export const useCartaConnection = () => useContext(CartaConnectionContext);
